<template lang="html">
<div class="all">
    <h1>Appsflyer link generator</h1>

    <form @submit.prevent='submit'>
        <div class="fields-241">

            <!-- Type Field -->
            <div class="field-5121">
                <label>Type</label>
                <div class="vals-options-5121">
                    <b-form-radio-group v-model="form.type" stacked :options="[{text:'Impression', value:'impression'},{text:'Click', value:'click'}]" required name="radio-inline-type"></b-form-radio-group>
                </div>
            </div>

            <!-- OS Field -->
            <div class="field-5121">
                <label>OS</label>
                <div class="vals-options-5121">
                    <b-form-radio-group v-model="form.os" stacked :options="[{text:'iOS', value:'ios'},{text:'Android', value:'android'}]" required name="radio-inline-os"></b-form-radio-group>
                </div>
            </div>

            <!-- Bundle ID Field -->
            <div class="field-5121">
                <label>Bundle ID</label>
                <b-form-input v-model="form.bundle_id" type="text" placeholder="Enter Bundle ID" required class="input"></b-form-input>
            </div>

            <!-- af_prt Field -->
            <div class="field-5121">
                <label>af_prt (optional)</label>
                <div class="vals-options-5121" style="width: 300px;">
                    <b-form-radio-group v-model="form.af_prt" stacked :options="afPartRows" required name="radio-inline-af_prt"></b-form-radio-group>
                    <b-form-input v-if="form.af_prt == ''" v-model="af_prt" type="text" placeholder="Enter Agency pid without af_part=" class="free-input"></b-form-input>
                </div>
            </div>

            <!-- PID Field -->
            <div class="field-5121">
                <label>pid</label>
                <div class="vals-options-5121">
                    <b-form-radio-group v-model="form.pid" stacked :options="pidRows" required name="radio-inline-pid"></b-form-radio-group>
                    <!-- <b-form-input v-if="form.pid === 'other'" v-model="pid" type="text" placeholder="Enter Pid" class="free-input"></b-form-input> -->
                    <b-form-select  v-if="form.pid === 'other'" :required="true" v-model="pid" :options="alternateOptions"></b-form-select>
                </div>
            </div>

            <!-- Campaign Name Field -->
            <div class="field-5121">
                <label>Campaign Name (c)</label>
                <b-form-input v-model="form.campaign_name" type="text" placeholder="Enter Campaign Name" required class="input"></b-form-input>
            </div>

            <!-- af_cost_model Field -->
            <div class="field-5121">
                <label>af_cost_model</label>
                <b-form-radio-group v-model="form.af_cost_model" stacked :options="[{text:'CPI', value:'CPI'},{text:'CPA', value:'CPA'}]" name="radio-inline-af_cost_model"></b-form-radio-group>
            </div>

            <!-- Is retargring -->
            <div class="field-5121">
                <label>Is Retargeting?</label>
                <div class="vals-options-5121">
                    <b-form-radio-group v-model="form.is_retargeting" stacked :options="['Yes', 'No']" required name="radio-inline-retarget"></b-form-radio-group>
                </div>
            </div>

            <!-- Additional Parameters Field -->
            <div class="field-5121">
                <label>Additional Parameters (optional)</label>
                <b-form-input v-model="form.additional_params" type="text" placeholder="Enter Additional Parameters" class="input"></b-form-input>
            </div>

        </div>

        <button type="submit" class="btn btn-primary">Generate Link</button>
    </form>

    <!-- Result Link Display -->
    <div class="result-div-41" v-if='resultLink'>
        <textarea readonly :value='resultLink' class="def f-251" @focus='onInputFocus'></textarea>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            resultLink: null,
            af_prt: "",
            pidRows: [],
            afPartRows: [],
            changedBy: null,
            pid: "",
            form: {
                type: null,
                os: null,
                bundle_id: "",
                af_prt: "",
                pid: "",
                campaign_name: "",
                af_cost_model: "",
                additional_params: "",
                is_retargeting: 'No'
            },
            urlTemplates: {
                ios: {
                    click: "https://app.appsflyer.com/id<bundle_id>?pid=<pid><af_prt_key_val>&af_siteid={pid}&af_c_id={offer_id}&af_click_lookback=7d&clickid={clickid}&c=<campaign_name>&af_sub_siteid={sub6}&af_channel=InApp_Display&af_ad_type=banner&af_adset=qwvAAAAA{sub7}&af_adset_id=qwvAAAAA&af_ad=asvBBBBB{sub2}&af_ad_id=asvBBBBB&af_media_type=app&af_model=iphone&af_cost_currency=usd&af_cost_model=<af_cost_model>&af_ua={device_ua}&af_os={os}&af_ip={ip}&idfa={sub5}",
                    impression: "https://impression.appsflyer.com/id<bundle_id>?pid=<pid><af_prt_key_val>&af_siteid={pid}&af_c_id={offer_id}&af_viewthrough_lookback=24h&clickid={clickid}&c=<campaign_name>&af_sub_siteid={sub6}&af_channel=InApp_Display&af_ad_type=banner&af_adset=qwvAAAAA{sub7}&af_adset_id=qwvAAAAA&af_ad=asvBBBBB{sub2}&af_ad_id=asvBBBBB&af_media_type=app&af_model=iphone&af_cost_currency=usd&af_cost_model=<af_cost_model>&af_ua={device_ua}&af_os={os}&af_ip={ip}&idfa={sub5}"
                },
                android: {
                    click: "https://app.appsflyer.com/<bundle_id>?pid=<pid><af_prt_key_val>&af_siteid={pid}&af_c_id={offer_id}&af_click_lookback=7d&clickid={clickid}&c=<campaign_name>&af_sub_siteid={sub6}&af_channel=InApp_Display&af_ad_type=banner&af_adset=qwvAAAAA{sub7}&af_adset_id=qwvAAAAA&af_ad=asvBBBBB{sub2}&af_ad_id=asvBBBBB&af_media_type=app&af_cost_currency=usd&af_cost_model=<af_cost_model>&af_ua={device_ua}&af_os={os}&af_ip={ip}&android_id={sub5}",
                    impression: "https://impression.appsflyer.com/<bundle_id>?pid=<pid><af_prt_key_val>&af_siteid={pid}&af_c_id={offer_id}&af_viewthrough_lookback=24h&clickid={clickid}&c=<campaign_name>&af_sub_siteid={sub6}&af_channel=InApp_Display&af_ad_type=banner&af_adset=qwvAAAAA{sub7}&af_adset_id=qwvAAAAA&af_ad=asvBBBBB{sub2}&af_ad_id=asvBBBBB&af_media_type=app&af_cost_currency=usd&af_cost_model=<af_cost_model>&af_ua={device_ua}&af_os={os}&af_ip={ip}&android_id={sub5}"
                }
            },
            alternateOptions: [],
        }
    },
    async created() {
        this.loading = true;
        await this.getdata();
        this.loading = false;
    },
    methods: {
        async getdata() {
            try {
                const pidData = await this.$http.post(this.resources.Management.getPid, {});
                this.pidRows = pidData.body.data.map(obj => {
                    if (obj.pid_text === 'Other') {
                        return
                    }
                    return {
                        text: obj.pid_text,
                        value: obj.pid_value
                    }
                })
                this.pidRows.push({text:'Other /\ Alternate', value:'other'})
            
                const afPartData = await this.$http.post(this.resources.Management.getAfPart, {});
                this.afPartRows = afPartData.body.data.map(obj => {

                    return {
                        text: obj.af_part_text,
                        value: obj.af_part_value
                    }
                })
                this.afPartRows.push({text:'Free Text', value:''})

                let alternateOptions = await this.$http.post(this.resources.Management.getAlternateSeat, {});
                console.log(alternateOptions);
                alternateOptions.body.data.sort((a, b) => a.name.localeCompare(b.name));
                this.alternateOptions = alternateOptions.body.data.map(row => {
                    return {
                        value: row.pid,
                        text: row.name
                    }
                })
                console.log(this.alternateOptions);

            } catch (err) {
                console.log(err);
            }
        },
        onInputFocus(v) {
            v.target.select && v.target.select()
        },
        submit() {
            const containsWhitespace = /\s+/;
            let failed = false
            let failed_input = ''
            if (containsWhitespace.test(this.form.bundle_id)) {
                failed = true
                failed_input = 'Bundle Id'
                
            } else if (containsWhitespace.test(this.af_prt) && this.form.af_prt === '') {
                failed = true
                failed_input = 'AF Prt' 

            } else if (containsWhitespace.test(this.pid) && this.form.pid === 'other') {
                failed = true
                failed_input = 'Pid' 
            } else if (containsWhitespace.test(this.form.campaign_name)) {
                failed = true
                failed_input = 'Campaign Name' 
            } else if (containsWhitespace.test(this.form.additional_params)) {
                failed = true
                failed_input = 'Additional Params' 
            }

            if (failed) {
                this.notifyError(`Error - ${failed_input} contains spaces.`)
                return
            }

            let form = this.form
            let {
                type,
                os,
                bundle_id,
                af_prt,
                pid,
                campaign_name,
                af_cost_model,
                additional_params,
                is_retargeting
            } = form

            if (af_prt === '' && this.af_prt !== '') {
                af_prt = this.af_prt;
            }

            if (pid === 'other' && this.pid !== '') {
                pid = this.pid;
            }

            if (os === "ios" && bundle_id.startsWith("id")) {
                bundle_id = bundle_id.substr(2)
            }
            let urlTemplate = this.urlTemplates[os][type]
            let resultLink = urlTemplate

            let af_prt_whole = ""
            if (af_prt) {
                af_prt_whole = "&af_prt=" + af_prt
            }

            resultLink = resultLink.replace("<af_prt_key_val>", af_prt_whole)
            resultLink = resultLink.replace("<af_prt>", af_prt)
            resultLink = resultLink.replace("<af_cost_model>", af_cost_model)
            resultLink = resultLink.replace("<bundle_id>", bundle_id)
            resultLink = resultLink.replace("<campaign_name>", campaign_name)
            resultLink = resultLink.replace("<pid>", pid)
            if (additional_params) {
                if (additional_params[0] !== "&") {
                    resultLink += '&'
                }
                resultLink += additional_params
            }
            
            this.resultLink = resultLink.trim();
            if (is_retargeting === 'Yes') {
                this.resultLink += "&is_retargeting=true&af_reengagement_window=30d&af_inactivity_window=10d"
            }
        },
        onkeydown(e) {
            const eventSource = e.key ? 'input' : 'list';
            this.changedBy = eventSource;
        },

    }
}
</script>

<style lang="css" scoped>
.field-5121 {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.field-5121>*:first-child {
    width: 200px;
}

.fields-241 {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.vals-options-5121>*:first-child {
    margin-right: 20px;
}

.result-div-41 {}

.f-251 {
    width: 710px;
    margin-top: 14px;
    padding: 5px;
    font-size: 14px;
    height: 146px;
    background: #f7f7f7;
    font-style: italic;
    color: #1d1d1d;
}

.input, input {
    width: 20%;
}

.free-input {
    width: 100%;
}

.input-select {
    padding: 6px 12px 6px 12px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    border-color: lightsteelblue;
    border-width: 1px;
    background-position: 98% 50%;
    cursor: auto;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=")
}
</style>
