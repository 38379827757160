<template>
    <form  @submit.prevent=''>
        <div v-if="loader">
            Loading ...
        </div>
        <InfoModal :is-show.sync="open_bundle_info"></InfoModal>
        <div v-if="!loader">
            <h6 v-if="form.name !== ''">
                This Global Offer ID: <span>{{ form.id }}</span>
                <button type="button" class="btn-right" @click="duplicateGlobalOffer()">Duplicate</button>
            </h6>
            <h1>Main</h1><br><br>
            <div class="field-container">
                 <!-- required -->
                <label>* <span v-if="isVtaSL">VTA</span> Offer Name</label>
                <input :disabled='true' v-model='form.name' class="pub-id-input form-control" ref="copyInput">
                <button type="button" button @click="copyText">Copy!</button>
            </div>
            <div class="field-container" v-if="isVtaSL">
                 <!-- required -->
                <label>* CTA Offer Name</label>
                <input :disabled='true' v-model='form.click_offer.name' class="pub-id-input form-control" ref="copyInputCta">
                <button type="button" button @click="copyTextCta">Copy!</button>
            </div>
            <div class="field-container">
                 <!-- required -->
                <label> * Advertiser</label>
                <cool-select :style="{width: '400px'}" class="cool-select-input" :disabled='!userHasPermission("smartlinks.edit")' v-model="adv_name" :items="advRows"/>
                <span v-if="this.advCsm != ''">CSM Name: {{ this.advCsm }}</span>
            </div>

            <div class="field-container">
                <!-- required -->
                <label>  <div @click="open_bundle_info = !open_bundle_info" ><b-icon class="icon-info" icon="exclamation-circle-fill" variant="warning"></b-icon></div> * Bundle ID</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.goffer_bundle_id' class="pub-id-input form-control" v-on:blur="check_bundle">
            </div>

            <div class="field-container">
                <label>* App Name</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.app_name' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                 <!-- required -->
                 <label>* {{ form.click_offer ? 'VTA Offer Affise ID' : 'Affise ID' }}</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.affiseID' class="pub-id-input form-control">
            </div>

            <template v-if='form.click_offer'>
                <!-- <div class="field-container">
                    <label class="adv-margin">CTA Offer Name</label>
                    <input class="form-row pub-id-input form-control" :disabled='!userHasPermission("smartlinks.edit")' v-model='form.click_offer.name'>
                </div> -->

                <div class="field-container">
                    <!-- check uniqe later -->
                    <label>* CTA Offer Affise ID</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.click_offer.affiseID' class="pub-id-input form-control">
                </div>
            </template>

            <div class="field-container">
                <!-- required -->
                <label>* Country</label>
                <cool-select :style="{width: '400px'}" :disabled='!userHasPermission("smartlinks.edit")' :isDisabled='true' v-model="geoSelected"
                    :items="geosSelect" />
                <ul class='selected-items' v-show='form.geo.length'>
                    <template v-if='userHasPermission("smartlinks.edit")'>
                        <li @click='userHasPermission("smartlinks.edit") ? onGeoDel(index) : e=>{}'
                            v-for='(geo, index) in form.geo'>
                            <i class="fa fa-times del-x" aria-hidden="true"></i>
                            <span> {{geo}} </span>
                        </li>
                    </template>
                </ul>
            </div>

            <div class="field-container">
                <label>Offer Region - Not functional</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.region' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <!-- required -->
                <label>* OS</label>
                <select required :disabled='!userHasPermission("smartlinks.edit")' class="def" v-model='form.os' disabled>
                    <option value="">Please Select One</option>
                    <option value="iOS">iOS</option>
                    <option value="Android">Android</option>
                </select>
            </div>

            <div v-if="isVtaSL" class="field-container">
                <label>CTR %</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' min="0" max="100" step="0.01" class='form-control pub-id-input' type="number" v-model.number='form.click_ctr'>
            </div>


            <div class="field-container">
                <label>Comments</label>
                <input v-model='form.sl_comments' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <label>Advertiser Requests</label>
                <input :disabled='!userHasPermission("smartlinks.sl_comments")' v-model='form.advertiser_requests' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <b-tooltip target="clickCap">Clickcap in UTC only</b-tooltip>
                <label >Clicks Cap(0=infinite) <b-icon id="clickCap" icon="info-circle" aria-hidden="true"></b-icon> </label>
                <input @keyup="formatCap" :disabled='!userHasPermission("smartlinks.edit")' v-model='form.cap' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                 <!-- required -->
                <label class="adv-margin">* Vertical</label>
                <b-form-select aria-required="true" class="select-w form-row" v-model="form.vertical_id" :options="vertical_rows" disabled></b-form-select><br /><br />
            </div>

            <div class="field-container">
                 <!-- required -->
                <label class="adv-margin">* Offer Type</label>
                <b-form-select aria-required="true" class="select-w form-row" v-model="form.offer_type" :options="offer_type"></b-form-select><br /><br />
            </div>

            <div class="field-container">
                 <!-- required -->
                <label class="adv-margin">MMP</label>
                <b-form-select aria-required="true" class="select-w form-row" v-model="form.mmp" :options="mmp_list"></b-form-select><br /><br />
            </div>

            <div v-if="form.mmp === 'AF'">
                <!-- Appsflyer Account  -->
                <div class="field-container">
                    <!-- required -->
                    <label>Appsflyer Account</label>
                    <b-form-select aria-required="true" class="select-w form-row" v-model="form.gappsflyer_account_id" :options="pidRows" @change="getAfAccountName"></b-form-select><br /><br />
                </div>

                <!-- Appsflyer Agency - dropdown - base on same page - af_part name - Also same as appsflyer-link-generator -->
                <div class="field-container">
                    <!-- required -->
                    <label>Appsflyer Agency</label>
                    <b-form-select aria-required="true" class="select-w form-row" v-model="form.appsflyer_agency_id" :options="afPartRows"></b-form-select><br /><br />
                </div>

                <!-- <div class="field-container">
                    <b-form-checkbox v-model="form.af_cb" @change="afCbClick">
                        AppsFlyer Account Monitor
                    </b-form-checkbox>
                    <div v-if="form.af_cb">
                        <select v-model='form.appsflyer_account_id' class="def select-234">
                            <option value=""></option>
                            <option v-for="r in appsflyer_list" :value="r.id"> {{ r.dm_appsflyer_name }}</option>
                        </select>
                    </div>
                </div> -->

                <div class="field-container">
                    <b-form-checkbox v-model="form.is_global_offer_pid_rotating" :value="true" :unchecked-value="false">
                       PID Rotator 
                    </b-form-checkbox>
                </div>

                <div class="field-container">
                    <b-form-checkbox v-model="form.active" :value="true" :unchecked-value="false">
                       Run AF Scraper
                    </b-form-checkbox>
                </div>

                <!-- <div v-if="form.is_global_offer_pid_rotating" class="field-container">
                    <label>Global PID Rotating Period</label>
                   <span> Every </span> <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.global_offer_pid_rotating_period' class="hours-input form-control"> Hours
                </div> -->
            </div>
             

        </div>
        <!-- Advanced - create button to move the next page - Disabled for create, enabled for edit. -->
        <div v-if="advancedLink.length !== 0">
            <a :href.prevent="advancedLink">Advanced</a> <span v-if="show_af_scraper_link"> | </span> <a v-if="show_af_scraper_link" :href.prevent="scraperLink">AF Scraper</a> <br><br>
        </div>
        <div v-if="advancedLink.length === 0">
            <span >Advanced</span><span> | AF Scraper</span><br><br>
        </div>

        
        <button class="btn-success btn" v-if="!edit && !loader" @click="submit">Create Offer</button>
        <button class="btn-success btn" v-if="edit && !loader" @click="submit">Update Offer</button>
    </form>
   
</template>

<script>
import countries from '@/assets/countries.js'
import {CoolSelect} from "vue-cool-select";
import utils from '../utils/utils.smartlinks';
import InfoModal from '../../../../components/InfoModal.vue'
    export default{
        components:{
            CoolSelect,InfoModal
        },
        data() {
            return {
                form: {
                    click_ctr: 2,
                    affiseID: 0,
                    name: '',
                    sl_comments: '',
                    weight: 100,
                    cap: '0',
                    frq: 24,
                    ip_frq: 24,
                    ip_frq_level: 'l3',
                    pid: null,
                    subs_query: null,
                    custom_url: null,
                    os_version: null,
                    os_version_v: "  all",
                    os_version_operator: ">=",
                    connection_type: "all",
                    isps: [],
                    blFiles: [],
                    blacklisting_subs: [],
                    list_id: 'NEW_PID_102',
                    ttl: 6,
                    is_rotating: 1,
                    delivery_frq: 100,
                    geo: [],
                    os: '',
                    appsflyer_agency_id: 0,
                    gappsflyer_account_id: 0,
                    is_global_offer_pid_rotating: false,
                    global_offer_pid_rotating_period: 2,
                    click_offer: {
                        affiseID: null,
                        name: null,
                        weight: null,
                        cap: '0',
                        frq: 24,
                        ip_frq: 24,
                        ip_frq_level: 'l3',
                        pid: null,
                        subs_query: null,
                        custom_url: null,
                        os_version: null,
                        isps: [],
                        blFiles: [],
                        blacklisting_subs: [],
                        ttl: null,
                        list_id: null,
                        is_rotating: 1,
                        isp_bl: 0,
                        advertiser_id: 0,
                        is_global_offer: true,
                        is_click_offer: 1,
                        goffer_bundle_id: '',
                    },
                    goffer_bundle_id: '',
                    is_global_offer: true,
                    list_id_link: '',
                    currently_selected_pid: '',
                    currently_selected_list_name: '',
                    last_rotate: '',
                    is_new_pid: '1',
                    af_cb: false,
                    appsflyer_account_id: 0,
                    isp_bl: 0,
                    advertiser_id: 0,
                    is_device_id_traffic: false,
                    is_main_offer_rotating: false,
                    region: '',
                    offer_type: 'CPA',
                    advertiser_requests: '',
                    vertical_id: '',
                    mmp: '',
                    app_name: '',
                },
                active: false,
                geo: [],
                all_geos: countries,
                geoSelected: null,
                advertisersData: [],
                advRows: [],
                advCsm: '',
                id: 0,
                isVtaSL: false,
                connectionTypes: ["all", "wifi", "3G"],
                pidRows: [],
                afPartRows: [],
                advancedLink: '',
                scraperLink: '',
                edit: false,
                loader: false,
                adv_name: '',
                open_bundle_info: false,
                offer_type:['CPA', 'CPI'],
                vertical_rows: [],
                appsflyer_list: [],
                mmp_list: [
                    {
                        text: 'None',
                        value: ''
                    },
                    {
                        text: 'AppsFlyer',
                        value: 'AF'
                    },
                    {
                        text: 'AppsFlyer Ex',
                        value: 'AF External'
                    },
                    {
                        text: 'Branch',
                        value: 'Branch'
                    },
                    {
                        text: 'Adjust',
                        value: 'Adjust'
                    },
                    {
                        text: 'Singular',
                        value: 'Singular'
                    },
                    {
                        text: 'Kochava',
                        value: 'Kochava'
                    },
                    {
                        text: 'Other',
                        value: 'Other'
                    }
                ],
                af_account_name: '',
                selected_mmp: '',
                use_global_offer_pid_rotating: false,
                show_af_scraper_link: false,
            }
        },
        async created() {
            this.loader = true
            await this.getAdvertisersList();

            // From route params -> if id = 0 then create
            // if id != 0 -> then fetch and fill the data correctly
            // if this.route param - isVta = 1 them set clickOffer to all , 
            let { id, isVta }= this.$route.query
            this.id = parseInt(id)
            this.isVtaSL = parseInt(isVta);
            if (! this.isVtaSL) {
                this.form.click_offer = null
            }
            if (this.id !== 0) {
                this.edit = true
                await this.fetchOffer();
                if (this.form.advertiser_id !== 0 && this.form.affiseID !== 0 && this.form.geo.length !== 0 && this.form.os.length !== 0 ) {
                    this.advancedLink = '/apps/smartlinks/global-offers-advanced?id=' + this.id + '&isVta=' + this.isVtaSL
                    this.scraperLink = '/apps/smartlinks/global-offers-scraper?offer_id=' + this.id + '&isVta=' + this.isVtaSL
                }
               // to update
                if (this.form.advertiser_id !== 0 ) {
                    this.onSelectAdvertiser()
                }

            }
            await this.getVerticalRows()
            await this.getAppsflyerList()
            if (this.form.appsflyer_account_id !== 0) {
                this.form.af_cb = true
            }
            
            await this.getAfPrtData()
            this.getAfAccountName()
            this.loader = false
        },
        computed: {
            geosSelect() {
                let a = []
                Object.keys(this.all_geos).filter(v => !this.form.geo.includes(v.code))
                .forEach(code => {
                    let name = this.all_geos[code]
                    a.push(name)
                })
                return a
            },
            geos() {
                let a = []
                Object.keys(this.all_geos).filter(v => !this.form.geo.includes(v.code))
                .forEach(code => {
                    let name = this.all_geos[code]
                    a.push({
                    label: name,
                    val: code
                    })
                })
                return a
            },
        },
        watch: {
            geoSelected(value) {
                if (value != null) {
                    this.onGeoAdd(value)
                }
            },
            'adv_name': {
                handler(adv_name) {
                    let advObj = this.advertisersData.filter(obj => {
                            if (`${obj.adv_company_name} - ${obj.affise_adv_name}` === adv_name) {
                                return obj;
                            }
                        })
                    
                    if (typeof advObj !== 'undefined') {
                        this.advCsm = advObj[0].adv_csm_name;
                        this.form.advertiser_id = advObj[0].id
                    }
                },
                deep: true,
            },
            ['form.mmp'](val) {
                if (val === 'AF') {
                    this.use_global_offer_pid_rotating = true;
                    this.form.global_offer_pid_rotating_period = 2;
                    this.form.is_global_offer_pid_rotating = true;
                    if (this.id === 0) {
                        this.form.active = true;
                    }
                   
                    if (this.form.gappsflyer_account_id === 0) {
                        this.show_af_scraper_link = true;
                    }
                } else if (val === 'Singular & Other') {
                    this.notifyError(`MMP 'Singular & Other' has been split. Please Choose an MMP and update the Offer`);
                    this.form.mmp = ""
                } else {
                    this.use_global_offer_pid_rotating = false;
                    this.form.is_global_offer_pid_rotating = false;
                    this.form.active = false;
                }
            },
            ['af_account_name'](val) {
                if (val.toLocaleLowerCase() === 'alternate seat' && this.form.mmp === 'AF') {
                    this.show_af_scraper_link = true;
                } else {
                    this.show_af_scraper_link = false;
                }
            },

        },
        methods: {
            async copyText() {
                const inputElement = this.$refs.copyInput;
                const textToCopy = inputElement.value;

                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        console.log("Text copied to clipboard!");
                    })
                    .catch((err) => {
                        console.error("Unable to copy text to clipboard", err);
                    });
            },
            async copyTextCta() {
                const inputElement = this.$refs.copyInputCta;
                const textToCopy = inputElement.value;

                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        console.log("Text copied to clipboard!");
                    })
                    .catch((err) => {
                        console.error("Unable to copy text to clipboard", err);
                    });
            },
            async getAppsflyerList() {
                const r = await this.$http.post(this.resources.Management.getAfData, {})
                this.appsflyer_list = r.body.data
            },
            afCbClick() {
                this.form.appsflyer_account_id = 0
            },
            async getVerticalRows() {
                const data = await this.$http.post(this.resources.Management.getDmVerticals, {});
                if (data.body.res === 'OK') {
                    this.vertical_rows = data.body.data.map(item => {
                        return {
                            text: item.vertical,
                            value: item.id
                        }
                    })
                }
            },  
            async formatCap(e) {
                let newValue = e.target.value;
                if (newValue !== null) {
                    const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.form.cap = result;
                }
            },
            async fetchOffer() {
                try {
                    this.loader = true;
                    const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.id))
                    const data = await JSON.parse(r.bodyText)
                
                    if (data.res !== 'OK') {
                        this.notifyError("Can\'t fetch offer")
                    }
                    if (data.data.is_new_pid === '1') {
                        data.data.list_id = "NEW_PID_" + data.data.list_id
                    }

                    
                    let offer = utils.formPlacement(data.data)
                    this.form = offer
                    console.log(offer);
                    this.selected_mmp = this.form.mmp
                    this.loader = false;
                    this.form.cap = this.form.cap.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } catch (err) {
                    console.log(err);
                    this.notifyError("Can\'t fetch offer")
                }
            }, 
            async getAdvertisersList() {
                try {
                    const res = await this.$http.post(this.resources.Management.getAdvertisersList);
                    if (res.body.data) {
                        this.advertisersData = res.body.data;
                        for (const k in this.advertisersData) {
                            if (this.advertisersData[k].affise_adv_name !== '') {
                                this.advRows.push(`${this.advertisersData[k].adv_company_name} - ${this.advertisersData[k].affise_adv_name}`)
                            } else {
                                this.advRows.push(`${this.advertisersData[k].adv_company_name}`)
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            getAfAccountName() {
                for (const k in this.pidRows) {
                    if (this.pidRows[k].value === this.form.gappsflyer_account_id && this.pidRows[k].text !== 'none') {
                        this.af_account_name = this.pidRows[k].text;
                    }
                }
                if (this.form.gappsflyer_account_id === 0) {
                    this.af_account_name = ''
                }
            },
            async getAfPrtData() {
                try {
                    const pidData = await this.$http.post(this.resources.Management.getPid, {});
                    this.pidRows = pidData.body.data.map(obj => {
                        return {
                            text: obj.pid_text,
                            value: obj.id
                        }
                    })
                    this.pidRows.push({
                        text: 'none',
                        value: 0
                    })
                
                    const afPartData = await this.$http.post(this.resources.Management.getAfPart, {});
                    this.afPartRows = afPartData.body.data.map(obj => {
                        return {
                            text: obj.af_part_text,
                            value: obj.id
                        }
                    })
                    this.afPartRows.push({
                        text: 'none',
                        value: 0
                    })

                } catch (err) {
                    console.log(err);
                }
            },
            onSelectAdvertiser() {
                let advObj = this.advertisersData.filter(obj => {
                        if (obj.id === this.form.advertiser_id) {
                            return obj
                        }
                    })

                if (typeof advObj !== 'undefined') {
                    this.advCsm = advObj[0].adv_csm_name
                    this.adv_name = `${advObj[0].adv_company_name} - ${advObj[0].affise_adv_name}`
                }
            },
            onGeoAdd(v) {
                var short = this.geos.filter(itm => {
                if (itm.label === v)
                    return (itm.val);
                });
                this.form.geo = [];
                this.form.geo.push(short[0].val)
            },
            onGeoDel(index) {
                this.form.geo.splice(index, 1)
            },
            checkVtaCta(offer) {
                if (offer.click_offer) {
                    if (offer.affiseID == offer.click_offer.affiseID) {
                        return true
                    }
                }
                return false
            },
            bundleIdValidation() {
                // For iOS, allow enter numbers only  (without "id" at the beginning).
                let pattern = ''
                // For Android, enter the complete bundle address (e.g., com.yourdomain.appname).
                if (this.form.os === 'iOS') {
                    pattern = /^[0-9]*$/
                } else {
                    pattern = /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/
                }
              
                return pattern.test(this.form.goffer_bundle_id)

            },
            async duplicateGlobalOffer() {
                let offer = this.form
                try {
                    const data = await this.$http.post(this.resources.GlobalOffers.duplicateGlobalOffer(offer.id))
                    const res = await JSON.parse(data.bodyText)

                    if (res.data) {
                        this.notifySuccess('Global offer duplicated successfully.')
                        window.location.href = '/apps/smartlinks/global-offers-edit?id=' + res.data + '&isVta=' + this.isVtaSL;
                    }

                } catch (err) {
                    console.log(err);
                    this.notifySuccess('Global offer did not duplicated due to an error.')
                }
            },
            async submit() {
                if (!this.validateVtaCtaAffiseId(this.form)) {
                    this.notifyError('Please fill CTA Affise ID')
                    return
                }
                if (this.checkVtaCta(this.form)) {
                    this.notifyError(`CTA and VTA can't have the same Affise ID`)
                    return     
                }

                if (this.form.advertiser_id === 0 || this.form.affiseID === 0 || this.form.geo.length === 0 || this.form.os.length === 0 || this.form.goffer_bundle_id === '' || this.form.app_name === ''  || this.form.vertical_id === 0 || this.form.offer_type === '') {
                    this.notifyError('Please fill mandatory fields.');
                    return;
                }

                if (! this.bundleIdValidation()) {
                    this.notifyError('Please check that your bundle ID is valid and try again.');
                    return;
                }

                this.form.name = `${this.form.app_name} ${this.adv_name}`
                if (this.form.mmp === 'AF') {
                    this.form.name += ` (${this.form.mmp}`
                    if (this.af_account_name !== '') {
                        this.form.name += `-${this.af_account_name})`
                    } else {
                        this.form.name += ')'
                    }
                } else if (this.form.mmp !== '') {
                    this.form.gappsflyer_account_id = 0
                    this.form.appsflyer_agency_id = 0
                    this.af_account_name = ''
                    this.form.name += ` (${this.form.mmp})`
                }
                let cta_offer_name = ''
                if (this.isVtaSL) {
                    cta_offer_name = this.form.name + ` ${this.form.offer_type} ${this.form.click_offer.affiseID} ${this.form.geo} ${this.form.os} ${'CTA'}`
                    this.form.name += ` ${this.form.offer_type} ${this.form.affiseID} ${this.form.geo} ${this.form.os} ${'VTA'}`
                    this.form.click_offer.name = cta_offer_name
                    
                } else {
                    this.form.name += ` ${this.form.offer_type} ${this.form.affiseID} ${this.form.geo} ${this.form.os} ${'CTA'}`
                }

                this.loader = true;
                let cap = this.form.cap.toString().replace(/[,]*/g, '')
                const form = {
                    ..._.omit(this.form, ['is_rotating', 'cap', 'geo']),
                    is_rotating: this.form.is_rotating,
                    cap: parseInt(cap),
                    geo: this.form.geo.join(',')
                }

                if (this.isVtaSL) {
                    form.click_offer = {
                        ..._.omit(form.click_offer, ['connection_type', 'blacklisting_subs', 'isps', 'subs_query', 'pid', 'blFiles', 'ttl', 'cap', 'advertiser_id', 'geo', 'os', 'appsflyer_agency_id', 'gappsflyer_account_id']),
                        connection_type: this.form.connection_type,
                        blacklisting_subs: this.form.blacklisting_subs,
                        isps: this.form.isps,
                        isp_bl: this.form.isp_bl,
                        subs_query: this.form.subs_query,
                        pid: this.form.pid,
                        blFiles: this.form.blFiles,
                        is_rotating: this.form.is_rotating,
                        ttl: this.form.ttl,
                        list_id: this.form.list_id,
                        os_version: form.os_version,
                        cap: parseInt(cap),
                        advertiser_id: this.form.advertiser_id,
                        geo: form.geo,
                        os: this.form.os,
                        appsflyer_agency_id: this.form.appsflyer_agency_id,
                        gappsflyer_account_id: this.form.gappsflyer_account_id,
                        appsflyer_account_id: this.form.appsflyer_account_id,
                        goffer_bundle_id: this.form.goffer_bundle_id,
                        region: this.form.region,
                        offer_type: this.form.offer_type,
                        advertiser_requests: this.form.advertiser_requests,
                        vertical_id: this.form.vertical_id,
                        mmp: this.form.mmp,
                        app_name: this.form.app_name,
                    }
                }

                if (this.form.is_rotating) {
                    form.ttl = this.ttl
                    
                } else {
                    form.ttl = 0
                    form.list_id = 0
                    this.form.ttl = 0
                    this.ttl = 0
                    if (this.isVtaSL) {
                        form.click_offer.ttl = 0
                        form.click_offer.list_id = 0
                        this.form.click_offer.ttl = 0
                    }
                }

                try {
                    let failed = 0
                    form.is_new_pid = "0"
                    var tListId = ''
                    if (String(form.list_id).includes("NEW_PID_")) {
                        tListId = form.list_id
                        form.list_id = parseInt(form.list_id.replace("NEW_PID_", ""))
                        form.is_new_pid = "1"
                    }
                   
                    if (typeof form.connection_type !== 'string' || form.connection_type === '') {
                        form.connection_type = 'All'
                    }

                    if (! this.edit) {
                        const r = await this.$http.post(this.resources.GlobalOffers.createGlobalOffer, form)
                        const data = await JSON.parse(r.bodyText)
                        if (data.res === 'OK') {
                            if (data.data.includes('not enough pids in the pool to rotate the global offer')) {
                                this.notifyError(`Not enough pids in the pool to rotate the global offer. \n Offer saved without rotation.`, 1000)
                            }
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            this.notifySuccess('Offer has created')
                            if (data.id && data.id !== 0) {
                                window.location.href = '/apps/smartlinks/global-offers-edit?id=' + data.id + '&isVta=' + this.isVtaSL
                                this.form.id = data.id
                            }
                        } else {
                            this.notifyError(data.data)
                            failed = 1
                        }
                      
                    }  else {
                        // edit global offer
                        const r = await this.$http.put(this.resources.GlobalOffers.updateGlobalOffer(this.id), form)
                        const data = await JSON.parse(r.bodyText)
                        if (data.res === 'OK') {
                            if (data.data.includes('not enough pids in the pool to rotate the global offer')) {
                                this.notifyError(`Not enough pids in the pool to rotate the global offer. \n Offer saved without rotation.`, 1000)
                            }
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            this.notifySuccess("Offer has updated")
                            window.location.reload()
                          
                        } else {
                            this.notifyError(data.data)
                            failed = 1
                        }
                    }

                    if (failed) {
                        this.loader = false;
                    }
                  
                } catch (err) {
                    if (err.msg.includes("Please fill")) {
                        this.notifyError("Make sure to fill affise ID and an offer name")
                    }
                    else {
                        this.notifyError("An error Occured. Please refresh the page")
                    }
                }
            },
            validateVtaCtaAffiseId(offer) {
                if (offer.click_offer) {
                    if (!offer.click_offer.affiseID || offer.click_offer.affiseID === 0) {
                        return false
                    } else {
                        return true
                    }
                }
                return true
            },
            async check_bundle() {
                const r = await this.$http.post(this.resources.GlobalOffers.validateBundleId, {
                    bundle_id: this.form.goffer_bundle_id
                });
                if (r.body.res === 'NOK') {
                    this.notifyError('First add Bundle to Bundle list');
                } else {
                    this.form.app_name = r.body.app_name;
                    this.form.os = r.body.os === 'ios' ? 'iOS' : 'Android';
                    this.form.mmp = r.body.mmp;
                    this.form.vertical_id = r.body.vertical_id;
                }
            }
        }
}

</script>

<style scoped>
    .form-row {
        width: 400px;
    }

    .adv-margin {
        margin-right: 13px;
    }

    .comment-span {
        font-size: 12px;
    }

    .icon-info {
        cursor: pointer;
    }

    .btn-right {
        float: right;
    }

    .hours-input {
        width: 5%;
    }

    .cool-select-input {
        border-radius: 0rem !important;
    }
</style>