<template>
    <div v-if="loading">Loding ...</div>
    <div v-else>
        <a href="/bundle-ids/home">Back</a>
        <h1 v-if="form.id > 0">Edit Bundle ID {{ form.id }}</h1>
        <h1 v-else>Create Bundle ID</h1>
        <!-- form -->
         <form>
            <div class="form-field">
                <label for="name">Bundle ID</label>
                <input required class="form-input" type="text" id="name" v-model.trim="form.bundle_id">
            </div>
            <div class="form-field">
                <label for="name">Preview Link</label>
                <input required class="form-input" type="text" id="name" v-model.trim="form.preview_link">
            </div>
            <div class="form-field">
                <label for="app-name">App Name</label>
                <input required class="form-input" type="text" id="app-name" v-model.trim="form.app_name">
            </div>
            <div class="form-field">
                <label for="os">OS</label>
                <b-form-select aria-required="true" class="form-input" id="os" v-model="form.os" :options="osOptions"></b-form-select>
            </div>
            <div class="form-field">
                <label for="vertical">Vertical</label>
                <b-form-select aria-required="true" class="form-input" id="vertical" v-model="form.vertical_id" :options="verticalOptions"></b-form-select>
            </div>
            <div class="form-field">
                <label for="mmp">MMP</label>
                <b-form-select aria-required="true" class="form-input" id="mmp" v-model="form.mmp" :options="mmpList"></b-form-select>
            </div> 
            <button type="submit" @click.prevent="submitForm" class="btn btn-success">Save</button>
         </form>
    </div>
</template>
<script>
export default {
   // name: 'HomeBundleIdsPage',
    components: {
  
    },
    data() {
        return {
            form:{
                id: 0,
                bundle_id: '',
                app_name: '',
                os: '',
                vertical_id: '',
                mmp: '',
                app_link: '',
                preview_link: ''
            },
            loading: false,
            verticalOptions: [],
            osOptions: [
                { value: 'ios', text: 'iOS' },
                { value: 'android', text: 'Android' },
            ],
            mmpList: [
                    {
                        text: 'None',
                        value: 'No MMP'
                    },
                    {
                        text: 'AppsFlyer',
                        value: 'AF'
                    },
                    {
                        text: 'AppsFlyer Ex',
                        value: 'AF External'
                    },
                    {
                        text: 'Branch',
                        value: 'Branch'
                    },
                    {
                        text: 'Adjust',
                        value: 'Adjust'
                    },
                    {
                        text: 'Singular',
                        value: 'Singular'
                    },
                    {
                        text: 'Kochava',
                        value: 'Kochava'
                    },
                    {
                        text: 'Other',
                        value: 'Other'
                    }
                ],
                app_links_strs: {
                    ios: 'https://apps.apple.com/',
                    android: 'https://play.google.com/store/apps/details?id='
                },
                copyMessage: '' 

        };
    },
    async created() {
        this.form.id = 0;
        if (typeof this.$route.params.id !== 'undefined' && parseInt(this.$route.params.id) > 0) {
            console.log('param id is', this.$route.params.id);
            this.form.id = parseInt(this.$route.params.id);
        }
        await this.fetchBundleData(this.form.id);
    },
    computed: {
    },
    methods: {
        async fetchBundleData(id = 0) {
            this.loading = true;
            try {

                let res = await this.$http.post(this.resources.BundleIDs.getBundleData, {
                    bundle: {
                        id: id
                    }
                })
                let data = await res.json();
                if (data.res == 'OK') {
                    if (this.form.id != 0) {
                        this.form = data.bundle_data;
                        // create copy link to app store or play store
                        this.form.app_link = this.createAppLink(this.form.os, this.form.bundle_id);
                        // assign mmp to form after MMP changes
                        this.form.mmp = this.editMMP(this.form.mmp);
                    }

                    let verticals = data.extra_data.vertical_list;
                    this.verticalOptions = verticals.map(v => {
                        return {
                            value: v.id,
                            text: v.vertical
                        }
                    });
                    
                } else {
                    this.notifyError('Error fetching bundle data');
                }

            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        async submitForm() {
            this.loading = true;
            let formData = {...this.form}
            // Remove "id" if it exists in the bundle_id
            console.log('form data', formData);
            if (formData.os === 'ios') {
                formData.bundle_id = formData.bundle_id.replace('id', '');
            }
            if (formData.os === 'android') {
                formData.bundle_id = formData.bundle_id.split('&')[0];
                formData.bundle_id = formData.bundle_id.split('?')[0];
            }

            let [validation_pass, err] = this.validateForm(formData);
            if (!validation_pass) {
                this.notifyError(err);
                this.loading = false;
                return;
            }

            try {
                let res = await this.$http.post(this.resources.BundleIDs.saveBundle, {
                    bundle: formData
                
                });
                let data = await res.json();
                // check for response - if not - check for the error_msg and render it to the user
                if (data.res != 'OK') {
                    this.notifyError(data.error_msg);
                    this.loading = false;
                } else {
                    this.notifySuccess('Bundle ID saved successfully');
                    await new Promise(resolve => setTimeout(resolve, 1500));
                    if (this.form.id == 0) {
                        window.location.href = '/bundle-ids/edit/' + data.bundle_data.id;
                    } else {
                        window.location.href = '/bundle-ids/edit/' + this.form.id;
                    }
                  
                }
            } catch (err) {
                console.log(err);
            }
        },
        validateForm(form) {
            if (form.bundle_id.length < 1) {
                return [false, 'Bundle ID is required'];
            }
            if (form.app_name.length < 1) {
                return [false, 'App Name is required'];
            }
            if (form.os.length < 1) {
                return [false, 'OS is required'];
            }
            if (form.vertical_id.length < 1) {
                return [false, 'Vertical is required'];
            }
            if (form.mmp.length < 1) {
                return [false, 'MMP is required'];
            }
            // validate form - remove spaces, remove “id” in case someone add “id123145” or “id 123145” 
            if (form.os === 'ios') {
                const iosBundleRegex = /^\d+$/;
                if (!iosBundleRegex.test(form.bundle_id)) {
                    return [false, 'Please enter a valid iOS bundle ID'];
                }
            }
            return [true, ''];
        },
        async copyToClipboard() {
            const textarea = this.$refs.appLinkTextarea;
            textarea.select();
            try {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(textarea.value);
                } else {
                    document.execCommand('copy');
                }
                this.copyMessage = 'Store link copied to clipboard!';
            } catch (err) {
                console.error('Failed to copy text: ', err);
                this.copyMessage = 'Failed to copy text';
            }
    
            window.getSelection().removeAllRanges();
            setTimeout(() => {
                this.copyMessage = '';
            }, 2000);
        },
        createAppLink(os, bundle_id) {
            let app_link = '';
            if (os === 'ios') {
                app_link = this.app_links_strs.ios + bundle_id;
            } else if (os === 'android') {
                app_link = this.app_links_strs.android + bundle_id;
            }
            return app_link;
        },
        editMMP(mmp) {
            let newMMP = mmp;
            if (mmp === "Singular & Other"){
                this.notifyError(`MMP 'Singular & Other' has been split. Please Choose an MMP and update the Bundle`);
                newMMP = "No MMP"
            }
            return newMMP;
        }
    },
  
};

</script>
<style scoped>
.form-field > * {
    display: inline-block;
}

.form-field > label {
    vertical-align: middle;
}

.form-field {
    margin-bottom: 20px;
    position: relative;
}

.form-field > label {
    margin-right: 40px;
    font-weight: bolder;
    width: 200px;
}
.form-input {
    min-width: 200px;
    max-width: 400px;

}

input {
    width: 400px;
    min-width: 200px;
    max-width: 400px;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    vertical-align: middle;
    background: #fff;
    background-size: 8px 10px;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

form {
    margin-top: 5%;
}

.app-link-div {
    min-width: 200px;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app-link-div p {
    color: green;
    font-weight: bold;
    margin-top: 10px;
    align-self: center;
}

.app-link-textarea {
    width: 100%;
    height: 80px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #c2cfd6;
    border-radius: 5px;
    resize: none;
    align-self: center;
}

</style>