<template lang="html">
    <div>
        <b-modal v-model="showDeleteModal" ok-title="Yes" cancel-title="No" @ok="deleteGlobalOffer(globalOfferSelected)">Are you sure that you want to delete global offer - {{ globalOfferSelected.name}}. <br>
            <b>Please notice - all associated offers will be removed.</b>
        </b-modal>
        <div class="filter-form-container">
            <form class="" @submit.prevent=''>
                <h5>Search</h5>
                <div class="form-inputs">
                    <label>By Global offer id / Offer name / Affise Offer ID / Bundle ID:</label>
                    <input ref="filter-sl-id" class="def filter-select" v-model='filterForm.id'>
                </div>
            </form>
        </div>
        <div class="filter-form-container">
            <form class="" @submit.prevent=''>
                <h5>Filter</h5>
                <div class="form-inputs">
                    <b-form-select class="def filter-select" v-model="selected_list" :options="list_types" @change="updateCurrentList"></b-form-select>
                    <b-form-select class="def filter-select" v-model="filter_value" v-if="current_list.length !== 0" :options="current_list" @change="filterTableRows"></b-form-select>
                    <button :class="'btn btn-danger'" v-if="filter_value !== null" @click="clearTableRowFilter">Clear</button>
                </div>
            </form>
        </div>
        <div>
            <a v-if="!show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Show Columns Selector</a>
            <a v-if="show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Hide Columns Selector</a>
            <div v-if="show_columns_selector">
                <input type="checkbox" @click="toggle_column(1)" :checked="show_c[1]"> Offer ID
                <input type="checkbox" @click="toggle_column(2)" :checked="show_c[2]"> Offer Name
                <input type="checkbox" @click="toggle_column(3)" :checked="show_c[3]"> VTA
                <input type="checkbox" @click="toggle_column(4)" :checked="show_c[4]"> Company
                <input type="checkbox" @click="toggle_column(5)" :checked="show_c[5]"> Affise offer ID
                <input type="checkbox" @click="toggle_column(6)" :checked="show_c[6]"> Clicks
                <input type="checkbox" @click="toggle_column(7)" :checked="show_c[7]"> Conversion
                <input type="checkbox" @click="toggle_column(8)" :checked="show_c[8]"> Revenue
            </div>
            <span style="display: none;">{{ r }}</span>
        </div>
        <table :style="{ height: tableHeight }" class="offers_tbl">
            <tr class="table_header_row bottom_border sticky_header" sticky_header>
                <td style="width: 30px;">

                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[1]">
                    <div>
                        <div class="th-title" @click="sortByField('id')">
                            Offer ID
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'id'">
                            <i :class='sortOptions.sortOrder === "desc" && sortOptions.sortByField === "offer_id" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[2]">
                    <div class="th-title" @click="sortByField('name')">
                        Offer Name
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[3]">
                    <div class="th-title" @click="sortByField('is_vta')">
                        VTA
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'is_vta'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[4]">
                    <div class="th-title" @click="sortByField('adv_name')">
                        Company
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'adv_name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[5]">
                    <div class="th-title" @click="sortByField('affiseID')">
                        Affise Offer ID
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'affiseID'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[6]">
                    <div class="th-title" @click="sortByField('clicks')">
                        Clicks
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'clicks'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[7]">
                    <div class="th-title" @click="sortByField('conversion')">
                        Conversion
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'conversion'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[8]">
                    <div class="th-title" @click="sortByField('revenue')">
                        Revenue
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'revenue'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
            </tr>

            <tr v-if="typeof totalRow !== 'undefined' && Object.keys(totalRow).length !== 0">
                <td class="noresize_td">
                    <div>
                        
                    </div>
                </td>
                <template v-for="field in fields">
<!--                     {{ field  }} -->
                    <td class="resize_td left_border" v-if="show_c[field.show_c]">
                        <span class="'total-row'" v-if="field.name && (field.name === 'revenue' || field.name === 'conversion' || field.name === 'clicks')" >{{ totalRow[field.name] | numFormat }}</span>
                        <span v-else :class="'total-row'">{{ totalRow[field.name] }}</span>
                    </td>
                </template>
            </tr>

            <tr v-for="(r, i) in rows_data_c" :class="i%2 == 0 ? 'grey-bg-td' : ''" v-if="r.show" :key="i">
             
                <td class="noresize_td">
                    <div class="div-actions" v-if="index_actions[r.id]" >
                        <a :href=r.href>Edit</a> <br>
                        <a href="javascript:void(0);" @click="deleteValidator(r)">Delete</a><br>
                        <a  href="javascript:void(0);" @click="duplicateGlobalOffer(r)">Duplicate</a><br>
                        <a  :href=r.updateHref>Update</a>
                    </div>
                    <div>
                        <button class="action-btn" @click="toggle_open_actions(r.id)" v-if="r.click_offer_id !== 0">...</button>
                        <button class="action-btn" @click="toggle_open_actions(r.id)" v-if="r.is_click_offer == 0 && r.click_offer_id == 0">...</button>
                    </div>
                </td>
                <td class="resize_td left_border" v-if="show_c[1]">
                    {{ r.id }}
                    <span class="warning-span" v-if="!r.event_1_valid || !r.event_2_valid">i</span>
                </td>
                <td class="resize_td left_border" v-if="show_c[2]">
                    {{ r.name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[3]" style="text-align: center;">
                   <input type="checkbox" disabled :checked="r.is_vta">
                </td>
                <td class="resize_td left_border" v-if="show_c[4]">
                    {{ r.adv_name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[5]">
                    <a :href="r.link_to_affise" target="_blank">{{ r.affiseID }}</a>
                </td>
                <td class="resize_td left_border" v-if="show_c[6]">
                    {{ r.clicks | numFormat}}
                </td>
                <td class="resize_td left_border" v-if="show_c[7]">
                    {{ r.conversion | numFormat}}
                </td>
                <td class="resize_td left_border" v-if="show_c[8]">
                    {{ r.revenue | numFormat}}
                </td>
            </tr>
        </table>
    </div>
</template>
  
<script>
import countries from '@/assets/countries.js'
export default {
    props: {
        rows_data: [], fetchGlobalOffers: null, tableHeight: {
            type: String,
            default: '600px'
        }, 
        advertisers: {
            type: Array,
            default: []
        },
        csms: {
            type: Array,
            default: []
        }
    },
    components: {

    },
    data() {
        return {
            show_columns_selector: false,
            show_c: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
            },
            r: 0,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'id'
            },
            globalOfferSelected:{},
            showDeleteModal: false,
            filterForm: {
                id: null
            },
            index_actions: {},
            adv_list: [],
            csm_list: [],
            countries: countries,
            country_list: [],
            list_types: [{
                value: 'adv_name',
                text: 'Company'
            },
            {
                value: 'csm_name',
                text: 'CSM'
            },
            {
                value: 'geo',
                text: 'Country'
            }],
            selected_list: [],
            current_list: [],
            filter_value: null,
            origin_list: [],
            fields: ['offer_id', 'offer_name', 'is_vta', 'advertiser_name', 'affise_id', 'clicks', 'conversion', 'revenue'],
        }
    },
    async created() {
        // get advertiser list
        // get csm list
        // get country list
        this.origin_list = this.rows_data
        // await this.getAdvertisersList()
        // await this.getCsmList()
        for (const k in this.countries) {
           this.country_list.push({
                value: k,
                text: this.countries[k]
           });
        }

        this.adv_list = this.advertisers.map(adv_obj => {
            return {
                value: adv_obj.adv_company_name,
                text: adv_obj.adv_company_name
            }
        })
        
        // Get only unique values
        this.adv_list = this.adv_list.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

        this.csm_list = this.csms.map(csmObj => {
            return {
                value: csmObj.csm,
                text: csmObj.csm
            }
        })

        this.csm_list.push({
            value: 'None',
            text: 'None'
        })
     
        if (localStorage.getItem('show_c_global')) {
            this.show_c = JSON.parse(localStorage.getItem('show_c_global'));
        }

        this.fields = this.fields.map((item, index) => {
            return {
                name: item,
                show_c: index + 1
            };
        });

        for (const k in this.rows_data) {
            this.index_actions[this.rows_data[k].id] = false;
        }
    },
    filters: {
        numFormat(v) {
            let i = isNaN(v) ? 0 : v
            return(Number(i).toLocaleString())
        },
    },
    methods: {
        async clearTableRowFilter() {
            this.selected_list = [];
            this.filter_value = null;
            this.current_list = [];
            this.rows_data = this.origin_list;
        },
        async filterTableRows() {
            this.rows_data = this.origin_list;
            let filtered_rows = []

            for (const k in this.rows_data) {
                if (this.selected_list === 'geo') {
                    if (this.rows_data[k][`${this.selected_list}`].includes(this.filter_value)){
                        filtered_rows.push(this.rows_data[k])
                    }
                } else {
                    if (this.rows_data[k][`${this.selected_list}`] === this.filter_value){
                        filtered_rows.push(this.rows_data[k])
                    }
                }
              
            }
            this.rows_data = filtered_rows;
        },
        async updateCurrentList() {
            if (this.selected_list === null) {
                return;
            }

            if (this.selected_list === 'adv_name') {
                this.current_list = this.adv_list;
            }

            if (this.selected_list === 'csm_name') {
                this.current_list = this.csm_list;
            }

            if (this.selected_list === 'geo') {
                this.current_list = this.country_list;
            }

        },
        async getAdvertisersList() {
            try {
                const res = await this.$http.post(this.resources.Management.getAdvertisersList, {}, {withCredentials: true})
                if (res.body.data) {
                    let data = res.body.data
                    this.adv_list = data.map(adv_obj => {
                        return {
                            value: adv_obj.id,
                            text: adv_obj.adv_company_name
                        }
                    })
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getCsmList() {
            try {
                const data = await this.$http.post(this.resources.Management.getCsmList, {},  {withCredentials: true})
                if (data.body.res === 'OK') {
                    let res = await data.body.data
                    this.csm_list = res.map(csmObj => {
                        return {
                            value: csmObj.csm,
                            text: csmObj.csm
                        }
                    })
                }
            } catch (err) {
                console.log(err);
            }
            
        },
        toggle_open_actions(id) {
            for (const k in this.index_actions) {
                if (id.toString() === k) {
                    this.index_actions[k] = !this.index_actions[k]
                    
                } else {
                    this.index_actions[k] = false
                }
                 
            }
            this.$forceUpdate();
        },
        toggle_columns_selector() {
            this.show_columns_selector = !this.show_columns_selector
        },
        toggle_column(i) {
            this.show_c[i] = !this.show_c[i]
            localStorage.setItem('show_c_global', JSON.stringify(this.show_c));
        },
        deleteValidator(offer) {
            this.globalOfferSelected = offer
            this.showDeleteModal = true
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }

            const nonIntFields = ['name', 'sl_comments', 'adv_name', 'is_vta_s']
            
            if (fieldName === 'is_vta') {
                for (let k in this.rows_data) {
                    this.rows_data[k].is_vta_s = this.rows_data[k].is_vta ? 'Yes' : 'No'
                    fieldName = 'is_vta_s'
                }
            }

            if (! nonIntFields.includes(fieldName)) {
                if (fieldName === 'weight') {
                    for (let k in this.rows_data) { 
                        this.rows_data[k].weight_int = this.rows_data[k].weight.split(' ')[0]
                    }
                    fieldName = 'weight_int'
                }
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) < parseInt(b[fieldName])) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) > parseInt(b[fieldName])) ? 1 : -1)
                }
            } else {
                // Remove spaces from the field
                for (let k in this.rows_data) { 
                    this.rows_data[k][fieldName] = this.rows_data[k][fieldName].trim()
                }
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) ? 1 : -1)
                }
            }
        },
        async deleteGlobalOffer(offer) {
            try {
                const r = await this.$http.post(this.resources.GlobalOffers.deleteGlobalOfferById(offer.id), offer)
                const res = await JSON.parse(r.bodyText)
                
                if (res.res !== 'OK') {
                    this.notifyError('Offer can\'t be deleted');
                } else {
                    this.notifySuccess('Offer has been deleted');
                }
                
                let globalOffersFilterd = this.rows_data.filter(item => item.id !== offer.id);
                globalOffersFilterd = globalOffersFilterd.filter(item => item.id !== offer.click_offer_id);

                this.rows_data = globalOffersFilterd
            } catch (err) {
                console.log(err);
                this.notifyError('Offer can\'t be deleted')
            }
        },
        async duplicateGlobalOffer(offer) {
            try {
                const data = await this.$http.post(this.resources.GlobalOffers.duplicateGlobalOffer(offer.id))
                const res = await JSON.parse(data.bodyText)

                if (res.data) {
                    this.notifySuccess('Global offer duplicated successfully.')
                    // await this.fetchGlobalOffers()
                    // const is_vta = offer.ctr_id !== 0 ? 1 : 0
                    const is_vta = offer.is_vta ? 1 : 0;
                    window.location.href = '/apps/smartlinks/global-offers-edit?id=' + res.data + '&isVta=' + is_vta;
                }

            } catch (err) {
                console.log(err);
                this.notifySuccess('Global offer did not duplicated due to an error.')
            }
        },
        filterData() {
            let filterID = this.filterForm.id.trim()
            this.rows_data.forEach(v => v.show = true)

            if (filterID) {
                filterID = filterID.toString().toLowerCase()
                for (const k in this.rows_data) {
                    let v = this.rows_data[k]
                    let to_add = false;

                    if (v.id.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }
                    if ( v.goffer_bundle_id.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (v.name.toLowerCase().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (v.affiseID.toString().includes(filterID)) {
                        this.rows_data[k].show = true;
                        to_add = true;
                    }

                    if (to_add) {
                        this.rows_data[k].show = true;
                    } else {
                        this.rows_data[k].show = false;
                    }
                }
            }
        },

    },
    computed: {
        rows_data_c() {
            return this.rows_data
        },
        totalRow() {
            let sum_row = {
                offer_id: '',
                offer_name: '',
                is_vta: '',
                advertiser_name: '',
                affise_id: '',
                clicks: 0,
                conversion: 0,
                revenue: 0
            }

            for (const k in this.rows_data) {
                sum_row.clicks += parseInt(this.rows_data[k].clicks)
                sum_row.conversion += parseInt(this.rows_data[k].conversion)
                sum_row.revenue += parseFloat(this.rows_data[k].revenue)
            }

            return sum_row
        }
    },
    watch: {
        ["filterForm.id"](val) {
            this.filterData()
        }
    }
}
</script>
  
<style lang="css" scoped>
.offers_tbl {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

.resize_td {
    resize: both;
    overflow: auto;
    padding: 10px;
}

.noresize_td {
    padding: 10px;
    position: relative;
}

td {
    font-size: 14px;
}

.left_border {
    border-left: 1px dotted gray;
}

.bottom_border {
    border-bottom: 1px solid gray;
}
.table_header_row {
    font-weight: bold;
}

.th-title {
    display: inline-block;
    cursor: pointer;
}

.th-icon {
    display: inline-block; 
    padding-left: 2px;
}

.grey-bg-td {
    background-color: rgb(209, 209, 209);
}

.global-offers-view { 
    padding-top: 40px;
    width: 100%;
}

.tr {
    padding-top: 10px;
}

.div-actions {
    position: absolute;
    background: rgb(238, 239, 239);
    border: 1px  rgb(160, 160, 160);
    box-shadow: 2px rgb(160, 160, 160);
    border-radius: 10px;
    z-index: 1012;
    min-width: 180px;
    max-width: 180px;
    padding: 15px 10px;
    margin-top: 30px;
    margin-left: 15px; 
}

.action-btn {
    background: transparent;
    border: transparent;
}

.filter-select {
    padding: 6px 7px;
    border-radius: 3px;
    width: 100%;
}

table {
  display: block;
  overflow-y: scroll;
}

.sticky_header {
    position: sticky;
    top: 0;
    background: white;
    word-break: break-word;
}

.warning-span {
    color: red;
    font-size: 9px;
    font-weight: bold;
    vertical-align: text-top;
    align-self: right;
}

</style>
